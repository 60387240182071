import type { TableProps } from 'antd';
import { Table, Typography } from "antd";
import { useEffect, useState } from 'react';
import '../App.css';
import { getBooks } from '../services/HomeService';

const { Title } = Typography;

interface DataType {
  name: string;
  part: string;
  numOfAudio: number;
  author: string;
  tags: string[];
}

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Tên sách',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Tập',
    dataIndex:'part' ,
    key: 'part',
  },
  {
    title: 'Tác giả',
    dataIndex: 'author',
    key: 'author',
  },
  {
    title: 'Số file audio',
    dataIndex: 'numOfAudio',
    key: 'numOfAudio',
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (value) => <a href={`/book/${value?.id}`}>Chi tiết</a>,
  },
];

function Homepage() {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const getTableData = async () => {
      const res = await getBooks() as any;
      if (res) setTableData(res?.data);
    };
    getTableData();
  }, []);

  return (
    <div className="App" style={{margin: '20px'}}>
      <Title level={3} className='text-center'>Kho sách nói Nguyện's</Title>
      <Table dataSource={tableData} columns={columns} />
    </div>
  );
}
  
export default Homepage;