/* global MediaSessionAction */

export const mediaMetadataControlNameList: Array<MediaSessionAction> = [
    "nexttrack",
    "pause",
    "play",
    "previoustrack",
    "seekbackward",
    "seekforward",
];
