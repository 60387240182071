export const STATUS = {
    SUCCESS: 501,
    GIAO_HANG: 500,
    GIAO_NHAN: 400,
    VAN_CHUYEN: 300,
    NHAN_HANG: 200,
    NHAN_HANG_TC: 105,
    GIAO_NHAN_HANG: 104,
    TAO_DON: 100,
    TON_CHUYEN_HOAN:505,
    DUYET_HOAN: 515,
    CHUYEN_HOAN: 502,
    HOAN_THANH_CONG: 504,
    KHONG_LIEN_LAC_DUOC: 506
}