import { Typography } from "antd";
import { useEffect, useState } from "react";
import { AudioPlayer, AudioPlayerControlSprite } from 'react-audio-player-pro';
import 'react-audio-player-pro/dist/style.css';
import { useParams } from "react-router-dom";
import { getBookDetail } from "../services/HomeService";
const { Title } = Typography;


function BookDetail() {
    const {id} = useParams();
    const [book, setBook] = useState({}) as any;
    const [tracks, setTracks] = useState([]) as any;

    useEffect(() => {
        const getDetail = async () => {
          const res = await getBookDetail(id as any) as any;
          if (res) {
            setBook(res?.data);
            if(res?.data?.bookDetails) {
                const details = res?.data?.bookDetails as any;
                let trackList = details?.map((item: any) => {
                    return {
                        src: item?.mp3Path,
                    }
                })
                setTracks(trackList);
            }
          }
        };
        getDetail();
    }, []);

    return (
        <div className="book-detail" style={{margin: '20px'}}>
            <Title level={4} className='text-center' style={{margin: '5px'}}>{book?.name}</Title>
            <Title level={5} className='text-center' style={{margin: '5px'}}>Tập: {book?.part}</Title>
            <Title level={5} className='text-center' style={{margin: '5px'}}>Tác giả: {book?.author}</Title>
            <div className="player-container" style={{border: '1px solid #dedede', margin: '10px 0 0'}}>
                <AudioPlayerControlSprite/>
                <AudioPlayer
                    trackList={tracks}
                    className=""
                    onDidMount={console.log}
                    defaultState={{
                        isMuted: false,
                        activeIndex: 0,
                        isShuffleOn: false,
                        isTrackListOpen: true,
                        repeatingState: 'none',
                    }}
                />
            </div>
        </div>
    );
}
export default BookDetail;
