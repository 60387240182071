import { BrowserRouter, Route, Routes } from "react-router-dom";
import BookDetail from "./views/BookDetail";
import Homepage from './views/Homepage';
import NoPage from "./views/NoPage";
import Tracking from './views/Tracking';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/tracking/:postCode" element={<Tracking />} />
        <Route path="/book/:id" element={<BookDetail />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
